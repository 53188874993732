@media (min-width: 992px){
    .onenhalf-col{
      -webkit-box-flex: 0;
      flex: 0 0 12.49999%;
      max-width: 12.49999%;
    }
    }
    
  @media (min-width: 992px){
    .twonhalf-col{
      -webkit-box-flex: 0;
      flex: 0 0 20.83333%;
      max-width: 20.83333%;
    }
    }
  
  @media (min-width: 351px){
    .fournhalf-col{
      -webkit-box-flex: 0;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    }
  
  @media (min-width: 351px){
    .sevennhalf-col{
      -webkit-box-flex: 0;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    }
  
  
  .fs1m {
      font-size: 1em;
  }
  
  .label-lg-300 {
      font-weight: 300;
      font-size: 1em;
  }
  
  .ls-head {
      letter-spacing: 0.1em;
  }
  
  .fw-lt{
      font-weight: 100;
  }
  
  .img-info {
      margin: 0 0 7px 4px;
  }
  
  .txtc {
      text-align: center;
  }
  
  .favorite.collapsed .fa-star,
  .favorite .fa-star-o {
    display: none;
  }
  
  .favorite.collapsed .fa-star-o,
  .favorite .fa-star {
    display: inline-block;
  }
  
  .favorite .fa-star-o:hover{
    color: rgb(255, 215, 0);
  }
  
  .favorite .fa-star {
    color: rgb(255, 215, 0);
  }
  
  .rating {display: inline;}
  .rating > input { display: none; } 
  
  .rating > label:before { 
    font-family: FontAwesome;
    display: inline-block;
    content: "\f004";
  }
  
  .rating > label { 
      color: rgb(216, 216, 216); 
      float: right;
  }
  
  .rating > label.heart:hover,
  .rating > label.heart:hover ~ label { 
      color:rgb(253, 54, 104);
    cursor:pointer;
  }
  
  .messages.collapsed .fa-angle-up,
  .messages .fa-angle-down {
    display: none;
    font-weight: 100;
  }
  
  .messages.collapsed .fa-angle-down,
  .messages .fa-angle-up {
    display: inline-block;
  }
  
  .readstate.collapsed .fa-envelope-open-o,
  .readstate .fa-envelope-o {
    display: none;
    font-weight: 100;
  }
  
  .readstate.collapsed .fa-envelope-o,
  .readstate .fa-envelope-open-o {
    display: inline-block;
    font-weight: 100;
  }
  
  .readstate .fa-envelope-open-o{
    color: lightgrey;
  }
  
  .readstate .fa-envelope-o:hover,
  .readstate .fa-envelope-open-o:hover {
    color: #50a1ff;
  }
  
  
  .unread label{
      font-weight: 700;
      color: #555555;
  }
  
  .read label{
      font-weight: 400;
      color:#757575;
  }
  
  .right{
      float: right;
  }
  
  .input-spinner {
      display: inline-block;
      display: flex;
  }
  
  .spin-field {
      border: 1px solid #232323;
      order: 2;
      padding: 0.275rem;
  
  }
  
  .btn-step{
    font-size: 1.25em;
    border:none;
    padding:0;
  }
  
  .stepup{
  order:3;
  }
  
  .stepdwn{
  order:1;
  }
  
  .accordion .noborder {
    border-top-color: #fff !important;
    border-right-color: #fff !important;
    border-bottom-color: #fff !important;
    border-left-color: #fff !important;
    margin-bottom: 16px;
  }
  
  a.redlink:hover {
    color:#ff4954;
    background-color: #fceeef;
  }
  
  .togbor{
    border: 1px solid #e9ecf0;
  }
  
  
  .site-listing .card-title {
    background-color: #eef4fc;
  }
  
  .site-listing .card-title a{
    padding: 8px 0px;
    background-color: #eef4fc;
  }
  
  .round { border-radius: 50%; }
  
  
  .lh-1{
    line-height: 1;
  }
  
  .fullwidth {
    padding-left: 0;
    padding-right: 0;
  }
  
  .border-primary {
    border: 1px solid #50a1ff;
  }

  .hover:hover {
    cursor: pointer;
  }