@media (min-width: 768px) { 

	.my-sites-scroll {
		overflow: auto;
		max-height: 700px;
		min-height: 700px;
	}
}

@media (max-width: 768px) {

	.my-sites-scroll.sandbox { 
		min-height: 620px;
	}
}